// Flow
import * as fcl from '@onflow/fcl'

// Transaction Helpers
import { basicTransaction } from './cadence/transactions/basicTransaction.js'
import { generateMintNFT } from './cadence/transactions/generateMintNFT.js'

// Signing Helper
import { Buffer } from "buffer"
import { generateFTScript } from './cadence/scripts/FTScripts.js'
import { generateNFTScript } from './cadence/scripts/NFTScripts.js'

// Configuration
const dotenv = require('dotenv')
dotenv.config({ path: `.env.${process.env.NODE_ENV}` })

export default function App() {

  // Flow config
  fcl.config()
    .put("app.detail.title", "MotoGP")
    .put("app.detail.icon", "https://media.indiedb.com/images/groups/1/24/23217/BFS_Mascot_2D_Colour.1.png")
    .put("discovery.wallet", "https://fcl-discovery.onflow.org/testnet/authn")
    .put("discovery.wallet.method", "POP/RPC")
    .put("flow.network", "testnet")
    .put('accessNode.api', 'https://rest-testnet.onflow.org')
    .put('discovery.authn.endpoint', "https://fcl-discovery.onflow.org/api/testnet/authn")
    .put('fcl.eventsPollRate', "2500")
    .put('0xMotoGPNFTContract', "0xb0e5f494fd8adfff")
    .put('MotoGPCard', "0xb0e5f494fd8adfff")
    .put('0xNonFungibleToken', "0x631e88ae7f1d7c20")
    .put('0xFungibleToken', "0x9a0766d93b6608b7")
    .put('0xREVV', "0x14ca72fa4d45d2c3")


  // Flow Functions
  window.logIn = async () => {
    let output = {}
    try {
      await fcl.authenticate()
      const user = await fcl.currentUser.snapshot()
      console.log(`Logged in as ${user.addr}`)
      output.result = user
    } catch (err) {
      output.error = err.message
    }
    return output
  }

  window.logOut = async () => {
    let output = {}
    try {
      fcl.unauthenticate()
      console.log('Logged out')
      console.log(await fcl.currentUser.snapshot())
      output.result = "Logged Out"
    } catch (err) {
      console.log(err.message)
      output.error = err.message
    }
    return output
  }

  window.signMessage = async (messageToSign = "Welcome to MotoGP") => {

    console.log(`Signing message: ${messageToSign}`)
    let output = {}
    try {
      // Sign message
      const messageHex = new Buffer.from(messageToSign, 'utf-8').toString('hex')
      const compSigs = await fcl.currentUser.signUserMessage(messageHex)
      const signature = compSigs[0].signature

      // Verify
      const verification = await fcl.AppUtils.verifyUserSignatures(messageHex, compSigs)
      console.log(`${signature} ${verification}`)
      output.result = compSigs
    } catch (err) {
      output.error = err.message
    }
    console.log(output)
    return output
  }


  // window.sendTransaction = async () => {
  //   try {
  //     console.log('Building transaction...')
  //     const transactionId = await fcl.mutate({
  //       cadence: basicTransaction, // TODO generate transaction script
  //       proposer: fcl.currentUser,
  //       payer: fcl.currentUser,
  //       authorizations: [fcl.currentUser],
  //       limit: 50
  //     })

  //     console.log('Sealing transaction...')
  //     const transaction = await fcl.tx(transactionId).onceSealed()
  //     console.log('Transaction sealed: ', transaction)
  //     return transaction
  //   } catch (err) {
  //     return err.message
  //   }
  // }

  // window.getRevvBalance = async (addresses = ['0x23735eee207e43eb']) => {
  //   try {
  //     // Check capabilities
  //     console.log('Checking Revv capabilities')
  //     let cadence = generateFTScript(
  //       'capability',
  //       'REVV',
  //       'REVV'
  //     )
  //     // Error handling
  //     if (cadence.includes('ERROR')) return

  //     const args = (arg, t) => [arg(addresses, t.Array(t.Address))]
  //     let capabilities = await fcl.query({
  //       cadence, args
  //     })

  //     // Error checking
  //     if (typeof (capabilities) === 'string') return

  //     console.log("Revv capability: ", capabilities)

  //     // Remove addresses without capabilities
  //     addresses = addresses.filter(address => capabilities[address])

  //     // Stop if no addresses have capabilities
  //     if (addresses.length === 0) {
  //       console.log('No addresses have capabilities')
  //       return
  //     }

  //     // Generate cadence script
  //     console.log('Getting revv balance...')
  //     cadence = generateFTScript(
  //       'balance',
  //       'REVV',
  //       'REVV'
  //     )

  //     // Error handling
  //     if (cadence.includes('ERROR')) return

  //     // Get balances
  //     let balances = await fcl.query({
  //       cadence, args
  //     })
  //     console.log('Revv balance: ', balances)
  //     return balances

  //   } catch (err) {
  //     console.log("Get FT balance unsuccessful | " + err.message)
  //   }
  // }

  // window.getNFTs = async (addresses = ['0x23735eee207e43eb']) => {
  //   try {

  //     // // Check capabilities
  //     console.log(`Checking NFT capabilities for ${addresses}...`)
  //     let cadence = generateNFTScript(
  //       'capability',
  //       'MotoGPCard',
  //       'MotoGPNFTContract'
  //     )
  //     // Error handling
  //     if (cadence.includes('ERROR')) return

  //     // Get capabilities
  //     const args = (arg, t) => [arg(addresses, t.Array(t.Address))]
  //     let capabilities = await fcl.query({
  //       cadence, args
  //     })
  //     console.log("NFT capabilities: ", capabilities)

  //     // Remove addresses without capabilities
  //     addresses = addresses.filter(address => capabilities[address])

  //     // Stop if no addresses have capabilities
  //     if (addresses.length === 0) {
  //       console.log('No addresses have capabilities')
  //       return
  //     }

  //     // Generate cadence script
  //     console.log(`Getting card metadata for ${addresses}`)
  //     cadence = generateNFTScript(
  //       'metadata',
  //       'MotoGPCard',
  //       'MotoGPNFTContract'
  //     )

  //     // Error handling
  //     if (cadence.includes('ERROR')) return

  //     // Get NFTS
  //     const NFTs = await fcl.query({
  //       cadence, args
  //     })
  //     console.log('NFTs: ', NFTs)
  //     return NFTs

  //   } catch (err) {
  //     console.log("Get NFT balance unsuccessful | " + err.message)
  //   }
  // }



  return (
    <div>

    </div>
  )
}